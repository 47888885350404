module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-131675946-2","defer":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"withWebp":true,"wrapperStyle":"transform: scale(1.1);"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-embed-video","gatsby-remark-relative-images","gatsby-remark-smartypants",{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"withWebp":true,"wrapperStyle":"transform: scale(1.2);margin:100px 0;display:block;"}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":false,"noInlineHighlight":false,"languageExtensions":[{"language":"superscript","extend":"javascript","definition":{"superscript_types":{}},"insertBefore":{"function":{"superscript_keywords":{}}}}],"prompt":{"user":"root","host":"localhost","global":false},"escapeEntities":{}}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"simse.io","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/wink.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"84f293f7315fb372fdbe10018ef3c3f3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
